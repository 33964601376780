import React from 'react';
import PropTypes from "prop-types";
import Posts from '@components/Posts';
import Post from '@components/Post'

import './index.scss';

const EditorPick = ({posts, post}) => (
    <div className="editor-pick">
        <h2 className="editor-pick__title">Nasz wybór</h2>
        <div className="editor-pick__content">
            <Post post={post} />
            {posts.length > 0 && <Posts posts={posts} />}
        </div>
    </div>
);

EditorPick.propTypes = {
    posts: PropTypes.array,
}

export default EditorPick;