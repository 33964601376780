import React from 'react';
import PropTypes from "prop-types";
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import './index.scss';

const Posts = ({posts, imageType}) => (
    <ul className="posts">
        {posts.map(post => (
            <li className="posts__item" 
                key={post.node.id}>
                <Link to={`/${post.node.category.slug}/${post.node.slug}/`}>
                    <Img
                        className={`posts__item-image${imageType === 'rounded' ? ' posts__item-image--rounded' : ''}`}
                        fluid={post.node.image.childImageSharp.fluid}
                        alt={post.node.title}
                    />
                </Link>
                <div className="posts__item-content">
                    <Link to={`/${post.node.category.slug}/${post.node.slug}/`}>
                        <h3 className="posts__item-title">{post.node.title}</h3>
                    </Link>
                    <time className="posts__item-date">{post.node.created_at}</time>
                </div>
            </li>
        ))}
    </ul>
);

Posts.propTypes = {
    posts: PropTypes.array,
    imageType: PropTypes.string,
}

export default Posts;