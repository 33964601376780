import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '@components/layout'
import Posts from '@components/Posts'
import About from '@components/About'
import EditorPick from '@components/EditorPick'
import SEO from "../components/seo"
import Newsletter from '@components/Newsletter'

import "./index.scss"
import "@sass/_button.scss";

const IndexPage = ({ data }) => {
    const lastPost = data.allStrapiPost.edges[0].node;
    const popularPosts = data.allStrapiPost.edges.filter(post => post.node.popular);
    const ourChoicePosts = data.allStrapiPost.edges.filter(post => post.node.our_choice);
    const ourChoicePostsLast = ourChoicePosts[ourChoicePosts.length- 1].node;
    const ourChoicePostsWithoutLast = ourChoicePosts.filter(post => post.node.id !== ourChoicePostsLast.id);

    return (
        <Layout>
            <SEO title="Strona główna" />
            <div className="content">
            <div className="homepage">
                <div className="homepage__main">
                    <p className="homepage__main-heading">MountainsLovers.pl - rodzice bliźniaków (Staś i Oluś)<br/>Łazikujemy po górach i nie tylko...<br/>Od niedawna z dwójką szkrabów 👨‍👩‍👦‍👦</p>
                    <div className="homepage__main-post">
                        <div className="homepage__main-post-category button button--primary">
                            <Link
                                className="homepage__main-post-link"
                                to={`/${lastPost.category.slug}/`}>{lastPost.category.name}
                            </Link>
                        </div>
                        <h2 className="homepage__main-post-title">
                            <Link
                                className="homepage__main-post-link"
                                to={`/${lastPost.category.slug}/${lastPost.slug}/`}>{lastPost.title}
                            </Link>
                        </h2>
                        <Link to={`/${lastPost.category.slug}/${lastPost.slug}/`}>
                            <Img
                                className="homepage__main-post-image"
                                fluid={lastPost.image.childImageSharp.fluid}
                                alt={lastPost.title}
                                loading="eager"
                            />
                        </Link>
                        <p className="homepage__main-post-short">
                            {lastPost.content.substring(0, 75)} ...
                        </p>
                        <div className="homepage__main-post-meta">
                            {lastPost.created_at}
                        </div>
                    </div>
                    <div className="homepage__main-editor-pick">
                        <EditorPick post={ourChoicePostsLast} posts={ourChoicePostsWithoutLast} />
                    </div>
                </div>
                <div className="homepage__sidebar">
                    <div className="homepage__sidebar-posts">
                        <div className="homepage__sidebar-buttons">
                            <button className="button button--primary button--big">
                                Popularne
                            </button>
                            {/* <button className="button button--transparent button--big">
                                Ostatnie
                            </button> */}
                        </div>
                        <Posts posts={popularPosts} imageType={"rounded"} />
                    </div>
                    <div className="homepage__sidebar-about">
                        <About />
                    </div>
                    <div className="homepage__sidebar-newsletter">
                        <h3 className="homepage__sidebar-title">Newsletter</h3>
                        <p className="homepage__sidebar-text">Dołącz do naszych subskrybentów!</p>
                        <Newsletter />
                    </div>
                </div>
            </div>
            </div>
        </Layout>
    )
}

export default IndexPage

export const pageQuery = graphql`
    query IndexQuery {
        allStrapiPost(sort: {fields: id, order: DESC}) {
            edges {
                node {
                    id
                    slug
                    created_at(formatString: "DD MMMM YYYY", locale: "pl")
                    image {
                        childImageSharp {
                            fluid(maxWidth: 600) {
                              ...GatsbyImageSharpFluid
                            }
                          }
                    }
                    popular
                    our_choice
                    title
                    category {
                        name
                        slug
                    }
                    content
                }
            }
        }
    }
`