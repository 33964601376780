import addToMailchimp from "gatsby-plugin-mailchimp"
import React from "react"

import './index.scss'

export default class MailChimpForm extends React.Component {
  constructor() {
    super()
    this.state = { email: "", message: null }
  }
  _handleSubmit = async e => {
    e.preventDefault()
    const result = await addToMailchimp(this.state.email)
    this.setState({message: result.result})
  }
handleChange = event => {
    this.setState({ email: event.target.value })
  }
render() {
    return this.state.message === "success" ? (
        <p className="message message--success">Dziękujemy za zapis do naszego newslettera!</p>
      ) : this.state.message === "error" ? (
        <p className="message message--error">Wystąpił błąd. Odśwież stronę i spróbuj ponownie.</p>
      ) : this.state.message === "Timeout" ? (
        <p className="message message--error">Wystąpił błąd. Wygląda na to, że Twoja przeglądarka blokuje możliwość zapisu na nasz newsleter. Spróbuj korzystając z innej przeglądarki lub wyłączając AdBlock'a.</p>
      ) : (
      <form className="newsletter" onSubmit={this._handleSubmit}>
          <label className="newsletter__label" htmlFor="outlined-email-input">E-mail:</label>
          <input
            type="email" 
            id="outlined-email-input"
            onChange={this.handleChange}
            className="input"
            name="outlined-email-input"
          />
        <button className="newsletter__button button button--primary button--big" type="submit">
         Zapisz się
        </button>
        <div className="newsletter__agreement">
            <input id="checkbox" name="checkbox" className="checkbox" type="checkbox" required />
            <label htmlFor="checkbox" className="newsletter__label">
                Wyrażam zgodę na otrzymywanie drogą elektroniczną na wskazany przeze mnie adres e-mail informacji od MountainsLovers.pl
            </label>
        </div>
      </form>
    )
  }
}